import React, {useState, useEffect, useRef, useContext} from "react";
import Header from "./header";
import GameHistory from "@components/gameHistory/gameHistory";
import Stream from "@components/videoStream";
import {connect} from "react-redux";
import ConnectMessage from "@components/connectMessage";
import {subscribeServerTime} from "@actions/workerActions";
import AlertMessages from "@components/alertMessage";
import BetBoard from "@components/betBoard/betBoard";
import WinnerList from "@components/roundInfo/winnerList";
import RoundInfo from "@components/roundInfo/roundInfo";
import {refreshSession, gameStart, connectToSocket, subGameInitData, subscribeGameFinish, subscribeCanceledGame} from "@actions/gameAction";
import Cards from "../components/cards/Cards";
import {FormattedMessage} from "react-intl";
import UserWon from "@components/UserWon";
import ActiveBets from "@components/activeBets/activeBets";
import BackgroundMusic from "@components/backgroundMusic";
import Inactivity from "@components/inactivity/inactivity";


function MainLayout({
                        subscribeServerTime, isConnected,refreshSession, gameStart,session, connectToSocket, 
                        subGameInitData, viewSetting,subscribeGameFinish, subscribeCanceledGame, detectNewLogin}) {
    const [online, setOnline] = useState(navigator.onLine);
    const [isShowOflineMessage, setIsShowOflineMessage] = useState(false);
    const prevOnlineValue = useRef(true);
    const refreshTimeout = useRef(0)


    useEffect(() => {
        function enable(e) { setOnline(true) }
        function disable(e) { setOnline(false) }
        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
        }
    },[])

    useEffect(() => {
        subscribeServerTime();
        gameStart();
        subscribeGameFinish();
        subscribeCanceledGame()
    }, [])

    
    useEffect(() => {
        if (!online) {
            setIsShowOflineMessage(true);
        }
        if (online && !isConnected && !prevOnlineValue.current && session) {
            refreshTimeout.current = setTimeout(() => refreshSession(session), 2000)
        }
        return () => {
            prevOnlineValue.current = online;
            if (!online && prevOnlineValue.current) {
                clearTimeout(refreshTimeout.current)
            }
        }
    }, [online])

    useEffect(() => {
        if (online && isConnected) {
            setIsShowOflineMessage(false);
        }
    }, [isConnected])

    useEffect(() => {
        if (session) {
            connectToSocket(session)
            subGameInitData();
        }

    }, [session])

    const refreshPage = () => {
        location.reload();
    }

    return (
        <div  className="mainContent white">
            <AlertMessages />
            <UserWon />
            <Header/>
            <Stream />
            <WinnerList />
            <BackgroundMusic />
            <Inactivity />
            <div className="fon"/>
            <div id="tooltip-root"/>
            <div className="playerArena">
       
                <div className="playerArena-section">
                    <Cards/>
                    <GameHistory/>
                </div>
                  
                    <BetBoard threeD={viewSetting} />
                    <RoundInfo  />
                    <ActiveBets/>
     
            </div>

            { isShowOflineMessage ? 
                <ConnectMessage title={<FormattedMessage id="no_internet_connection"/>}
                                description={<FormattedMessage id="please_check_internet_connection_and_try_again" values={{br: <br/>}}/>}
                                button={{
                                    active: false
                                }}
                /> : ""
            }
            {
                detectNewLogin ?
                    <ConnectMessage title={<FormattedMessage id="new_login_detected"/>}
                                    description={<FormattedMessage id="new_login_detected_msg" values={{br: <br/>}}/>}
                                    button={{
                                        active: true,
                                        text: <FormattedMessage id="refresh"/>,
                                        callBack: refreshPage
                                    }}
                    />:""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isConnected: state.globalInfo.isConnected,
        session: state.globalInfo.session,
        viewSetting: state.viewSetting,
        detectNewLogin: state.globalInfo.detectNewLogin
    }
}

const mapDispatchToProps = {
    subscribeServerTime,
    refreshSession,
    gameStart,
    connectToSocket,
    subGameInitData,
    subscribeGameFinish,
    subscribeCanceledGame
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);