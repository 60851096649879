import React, {useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ActiveBetsIcon, NoDataIcon} from "@resource";
import {Prediction} from "@constants/Enums"
import classNames from "classnames";
import {abbrNum} from "@services/betService";

function ActiveBets({activeBets}) {
    const [opened, setOpened] = useState(true)
    return (
        <div className={classNames("active-bets", {opened})}>
            <div className="active-bets-header">
                <div className="active-bets-header-button" onClick={() => setOpened(prev => !prev)}>
                    <div className="title">
                        <FormattedMessage id="my_bets"/>
                        <div className={classNames("icon", {opened})} >
                            <ActiveBetsIcon/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames("active-bets-table", {opened, centered:  !activeBets.length})}>
                {
                    activeBets.length ? <>
                        <table>
                            <thead>
                            <tr>
                                <th><FormattedMessage id="nation"/></th>
                                <th><FormattedMessage id="odds"/></th>
                                <th><FormattedMessage id="stake"/></th>
                            </tr>
                            </thead>
                        </table>
                        <div className="active-bets-table-body scrollable">
                            <table>
                                <tbody>
                                {activeBets.map((item, index) => <tr key={index}>
                                    <td>
                                        <span className="done">{item.accepted ? <FormattedMessage id="accepted"/> : "" }</span>
                                        <FormattedMessage id={Prediction[item.Prediction]}/>
                                    </td>
                                    <td>{item.Odd}</td>
                                    <td>
                                        {(item.Stake)}</td>
                                </tr>)}

                                </tbody>
                            </table>
                        </div>
                    </> : <div className="active-bets-table-no-data">
                        <div className="icon">
                            <NoDataIcon />
                        </div>
                        <div className="title">
                            <FormattedMessage id="no-data" />
                        </div>

                    </div>
                }

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        activeBets: state.activeBets
    }
}

export default connect(mapStateToProps, null)(ActiveBets)