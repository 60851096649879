import {
    SET_BETS,
    RESET_ACTIVE_BET,
    SET_ALL_BETS,
    CREATE_BETS
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function activeBetsReducer(state = initialStore.userBetList, action) {
    switch (action.type) {
        case CREATE_BETS:
        case SET_BETS:
        case SET_ALL_BETS:
            return action.bets;
        case RESET_ACTIVE_BET :
            return initialStore.userBetList;
        default:
            return state;
    }
}
