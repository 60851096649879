import React,  { useEffect }  from "react";
import {connect} from "react-redux";
import {toggleInactivityAction} from"@actions/inactivityAction";
import { getLobbyUrl, isOpenedByLobby } from "@utils/HttpUtil";
import { inIframe } from "@utils/CommonUtil";
import InfoPopUp from "@components/infoPopUp";
import {FormattedMessage} from "react-intl";


function Inactivity({inactivity, toggleInactivityAction}) {

    useEffect(() => {
        if (inactivity.count === parseInt(process.env.REACT_APP_INACTIVITY_COUNT)) {
            toggleInactivityAction(true)
        }

    }, [inactivity.count])

    const goLobby = () => {
        
        if (isOpenedByLobby() && inIframe()) {
            window.parent.postMessage("CLOSE", getLobbyUrl());
        } else {
            top.window.location.href = document.referrer;
        }
    }

    return (
        <InfoPopUp title={<FormattedMessage id="not_active_title"/>}
                              show={inactivity.count === parseInt(process.env.REACT_APP_INACTIVITY_COUNT)}
                              description={<FormattedMessage id="not_active_description" />}
                              handleCancel={() => toggleInactivityAction(false)}
                              submit={() => goLobby()}
                              okButtontext="home"
                              cancelButtontext="reconnect"
                              className ="reconnect"/>
    )
}

export const mapStateToProps = (state) => {
    return {
        inactivity: state.inactivity,
    }
}

const mapDispatchToProps = {
    toggleInactivityAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Inactivity)