import React, {useEffect, useState} from "react";
import ToolTip from "@components/toolTip/toolTip";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";
import RoundInfoItem from "@components/history/RoundHistory/RoundInfoItem";
import CardList from "@components/history/RoundHistory/CardList";
import {FormattedMessage} from "react-intl";
import {CardsSuit, Prediction, CardTypeMap} from "@constants/Enums";
import {ModalCloseIcon} from "@resource";

function GameHistoryLastResult({round, ...restProps}) {

    const [activeRound, setActiveRound] = useState(false)
    const [bankerCards, setBankerCards] = useState([])
    const [playerCards, setPlayerCards] = useState([])

    const trigger = (isActive) => {
        setActiveRound(isActive)
    }
    useEffect(() => {
        let bankerCards = [], playerCards = [];
        if (round.BankerCards && round.BankerCards.length) {
            round.BankerCards.forEach(card => {
                bankerCards.push({type: CardTypeMap[card.Type], suit: CardsSuit[card.Suit]})                
            });
            setBankerCards(bankerCards)
        }

        if (round.PlayerCards && round.PlayerCards.length) {
            round.PlayerCards.forEach(card => {
                playerCards.push({type: CardTypeMap[card.Type], suit: CardsSuit[card.Suit]})               
            });
            setPlayerCards(playerCards)
        }

    }, [round])

    const getContent = () => {
        return (
            <div className="game-history-last-results-item">
                <div className="game-history-last-results-item-header">
                    <div className="title">
                        <FormattedMessage id="round_id" />: {round.RoundId}
                    </div>
                    <button className="circled-button small">
                        <ModalCloseIcon id={`GameHistoryLastResult${round.RoundId}`}/>
                    </button>
                </div>
                <div className="game-history-last-results-item-round-info">
                    <RoundInfoItem time={round.StartTime}
                                   timeTitle="game_start"/>
                    <RoundInfoItem time={round.EndTime}
                                   timeTitle="game_end"/>
                </div>
                <div className="game-history-last-results-item-round-cards">
                    <CardList player={<FormattedMessage id="player" />}
                              cards={{cards: playerCards}}
                              points={round.PlayerCardsWeight}
                              winner={round.Won === Prediction.Player || round.Won === Prediction.Both}/>

                    <CardList player={<FormattedMessage id="banker" />}
                              cards={{cards: bankerCards}}
                              points={round.BankerCardsWeight}
                              winner={round.Won === Prediction.banker || round.Won === Prediction.Both}/>
                </div>
            </div>
        )
    }
    return(
        <ToolTip title={getContent()} position={"top"} type="popup" triggerCallBack={trigger}{...restProps}>
            <GameHistoryIcon won={round.Won} type="large" round={round} active={activeRound}/>
        </ToolTip>
    )
}

export default GameHistoryLastResult