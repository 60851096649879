import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import { setMusic} from "@actions/soundMusicAction";
import {MusicOnIcon, MusicOffIcon} from "@resource";
import VolumController from "./volum";
import ToolTip from "@components/toolTip/toolTip";


function GameMusic({isOn, setMusic}) {
    return(
        <li className={classNames(`template-button-item hybrid-item music`)} onClick={() => setMusic(!isOn)}>
            <ToolTip title={<VolumController />} position={"bottom"} type="popup" className="music" disabled={isOn}>
                {isOn ? <MusicOnIcon /> : <MusicOffIcon />}
            </ToolTip>
        </li>
    )
}

function mapStateToProps(state) {
    return {
        isOn: state.backgroundMusic
    }
}

const mapDispatchToProps = {
    setMusic
}


export default connect(mapStateToProps, mapDispatchToProps)(GameMusic)