export function numberWithSpaces(x) {
    let val = x
    if (typeof x === "number") {
        val = Number(x.toFixed(2))
    }
    return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function floatNumberWithSpaces(x, defaultReturnedValue = "-") {
    if (!x) return defaultReturnedValue;
    let val = x
    if (typeof x === "number") {
        val = Number(x.toFixed(2))
    }
    let parts = val.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}