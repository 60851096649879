import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {setViewSettings} from "@actions/viewSettingsAction";
import {getLocalStorage} from "@services/storageService";
import {FormattedMessage} from "react-intl";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";
import {SwitchIcon} from "@resource";

function SwitchView({setViewSettings}) {
    const [threeD, setThreeD] = useState(getLocalStorage("viewSettings"));

    useEffect(() => {
        setThreeD(threeD);
        setViewSettings(threeD);
    }, [threeD])

    return (
        <li className={classNames(`template-button-item`)} onClick={() => setThreeD(prev => !prev)}>
            <ToolTip title={<FormattedMessage id={"switch-view"}/>} position={"bottom"}>
                {<SwitchIcon/>}
            </ToolTip>
        </li>
    )
}

const mapDispatchToProps = {
    setViewSettings,
}

export default connect(null, mapDispatchToProps)(SwitchView)