import React from "react";
import classNames from "classnames";
import { Prediction } from "@constants/Enums";
import { FormattedMessage } from "react-intl";

function SmallBoard({ odd, disabledAll, enabledBetAreas, selectedBoard, selectBoard }) {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" className="board-svg smallBoard" viewBox="0 0 344 98.001">
      <defs>
        <linearGradient id="small-linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#eaeaec" stopOpacity="0.502" />
          <stop offset="1" stopColor="#eaeaec" stopOpacity="0.702" />
        </linearGradient>
        <linearGradient id="small-linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#0abf9e" stopOpacity="0.502" />
          <stop offset="1" stopColor="#0abf9e" stopOpacity="0.702" />
        </linearGradient>
        <linearGradient id="small-linear-gradient-3" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#db3f7d" stopOpacity="0.502" />
          <stop offset="1" stopColor="#db3f7d" stopOpacity="0.702" />
        </linearGradient>
        <linearGradient id="small-linear-gradient-6" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#7b838c" stopOpacity="0.702" />
          <stop offset="1" stopColor="#7b838c" stopOpacity="0.502" />
        </linearGradient>
        <linearGradient id="small-linear-gradient-7" y1="0" y2="1" gradientUnits="objectBoundingBox" >
          <stop offset="0" stopColor="#7b838c" stopOpacity="0.702" />
          <stop offset="1" stopColor="#7b838c" stopOpacity="0.502" />
        </linearGradient>
      </defs>
      <g transform="translate(-460.994 -567.999)" onClick={()=> selectBoard(Prediction.Tie, odd[Prediction.Tie])}
      className={classNames("tie board-svg-item", {
        disabled: disabledAll,
        active: selectedBoard.board === Prediction.Tie })}>
        <g transform="translate(600.998 606)" stroke="#eaeaec" strokeidth="1" fill="url(#small-linear-gradient)">
          <rect width="64" height="52" rx="10" stroke="none" />
          <rect x="0.5" y="0.5" width="63" height="51" rx="9.5" fill="none" />
        </g>
        <text transform="translate(621 627.002)" fill="#fff" fontSize="14" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan className="text-tie" x="0" y="0">TIE</tspan></text>
        <text transform="translate(621 647.002)" fill="#fc0" fontSize="14" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="0" y="0">{odd[Prediction.Tie]}</tspan></text>
      </g>
      <g transform="translate(-15.996 -258.999)" onClick={()=> selectBoard(Prediction.Player, odd[Prediction.Player])}
      className={classNames("player board-svg-item", {
        disabled: disabledAll,
        active: selectedBoard.board === Prediction.Player })}>
        <g transform="translate(-18438 -5463)" fill="url(#small-linear-gradient-2)">
          <path d="M 18613.498046875 5815.49853515625 L 18527.001953125 5815.49853515625 C 18521.76171875 5815.49853515625 18517.498046875 5811.23779296875 18517.498046875 5806.00048828125 L 18517.498046875 5765.99755859375 C 18517.498046875 5760.76025390625 18521.76171875 5756.49951171875 18527.001953125 5756.49951171875 L 18613.498046875 5756.49951171875 L 18613.498046875 5757.4990234375 L 18604 5757.4990234375 C 18600.66015625 5757.4990234375 18597.51953125 5758.79931640625 18595.16015625 5761.16015625 C 18592.798828125 5763.52197265625 18591.498046875 5766.662109375 18591.498046875 5770.001953125 L 18591.498046875 5802.0009765625 C 18591.498046875 5805.3388671875 18592.798828125 5808.4775390625 18595.16015625 5810.8388671875 C 18597.5234375 5813.19921875 18600.662109375 5814.4990234375 18604 5814.4990234375 L 18613.498046875 5814.4990234375 L 18613.498046875 5815.49853515625 Z" stroke="none" />
          <path d="M 18603.88671875 5814.99853515625 C 18600.45703125 5814.9697265625 18597.236328125 5813.62060546875 18594.806640625 5811.1923828125 C 18592.3515625 5808.736328125 18590.998046875 5805.47216796875 18590.998046875 5802.0009765625 L 18590.998046875 5770.001953125 C 18590.998046875 5766.52880859375 18592.349609375 5763.26318359375 18594.806640625 5760.806640625 C 18597.234375 5758.37841796875 18600.453125 5757.02880859375 18603.884765625 5756.99951171875 L 18527.001953125 5756.99951171875 C 18522.037109375 5756.99951171875 18517.998046875 5761.0361328125 18517.998046875 5765.99755859375 L 18517.998046875 5806.00048828125 C 18517.998046875 5810.9619140625 18522.037109375 5814.99853515625 18527.001953125 5814.99853515625 L 18603.88671875 5814.99853515625 M 18613.998046875 5815.99853515625 L 18613.99609375 5815.99853515625 L 18613.998046875 5815.99853515625 Z M 18613.998046875 5815.99853515625 L 18527.001953125 5815.99853515625 C 18521.486328125 5815.99853515625 18516.998046875 5811.513671875 18516.998046875 5806.00048828125 L 18516.998046875 5765.99755859375 C 18516.998046875 5760.484375 18521.486328125 5755.99951171875 18527.001953125 5755.99951171875 L 18613.998046875 5755.99951171875 L 18613.998046875 5757.9990234375 L 18604 5757.9990234375 C 18600.79296875 5757.9990234375 18597.779296875 5759.2470703125 18595.513671875 5761.513671875 C 18593.24609375 5763.78173828125 18591.998046875 5766.7958984375 18591.998046875 5770.001953125 L 18591.998046875 5802.0009765625 C 18591.998046875 5805.205078125 18593.24609375 5808.2177734375 18595.513671875 5810.4853515625 C 18597.78125 5812.7509765625 18600.794921875 5813.9990234375 18604 5813.9990234375 L 18613.998046875 5813.9990234375 L 18613.998046875 5815.99853515625 Z" stroke="none" fill="#7bb1a7" />
        </g>
        <text transform="translate(92 317.002)" fill="#fff" fontSize="12" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="0" y="0">PLAYER</tspan></text>
        <text transform="translate(92 337.002)" fill="#fc0" fontSize="12" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="0" y="0">{odd[Prediction.Player]}</tspan></text>
      </g>
      <g transform="translate(184.002 34)" onClick={()=> selectBoard(Prediction.Banker, odd[Prediction.Banker])}
      className={classNames("banker board-svg-item", {
        disabled: disabledAll,
        active: selectedBoard.board === Prediction.Banker })}>
        <g transform="translate(97 59.999) rotate(180)" fill="url(#small-linear-gradient-3)">
          <path d="M 96.50200653076172 59.49850082397461 L 10.00170707702637 59.49850082397461 C 4.763444900512695 59.49850082397461 0.5018073320388794 55.23686218261719 0.5018073320388794 49.99860000610352 L 0.5018073320388794 9.998100280761719 C 0.5018073320388794 4.759837627410889 4.763444900512695 0.4982001483440399 10.00170707702637 0.4982001483440399 L 96.50200653076172 0.4982001483440399 L 96.50200653076172 1.498900175094604 L 87.00212097167969 1.498900175094604 C 83.66316986083984 1.498900175094604 80.524169921875 2.799050092697144 78.16334533691406 5.159850120544434 C 75.80255889892578 7.520649909973145 74.50241088867188 10.65964984893799 74.50241088867188 13.99860000610352 L 74.50241088867188 45.99810028076172 C 74.50241088867188 49.33711242675781 75.80254364013672 52.47626113891602 78.16332244873047 54.83728790283203 C 80.52420806884766 57.19838714599609 83.6632080078125 58.49869918823242 87.00212097167969 58.49869918823242 L 96.50200653076172 58.49869918823242 L 96.50200653076172 59.49850082397461 Z" stroke="none" />
          <path d="M 86.92910766601562 58.99850845336914 C 83.48393249511719 58.97967147827148 80.24781799316406 57.62914276123047 77.80976104736328 55.19081497192383 C 75.35456085205078 52.7353630065918 74.00241088867188 49.47066879272461 74.00241088867188 45.99811172485352 L 74.00241088867188 13.99860382080078 C 74.00241088867188 10.52608394622803 75.35457611083984 7.261523246765137 77.80979919433594 4.806296825408936 C 80.26502990722656 2.351070165634155 83.52958679199219 0.998910129070282 87.00212097167969 0.998910129070282 L 96.00201416015625 0.998203456401825 L 10.00170707702637 0.998203456401825 C 5.03914737701416 0.998203456401825 1.001814007759094 5.035536766052246 1.001814007759094 9.998096466064453 L 1.001814007759094 49.99860382080078 C 1.001814007759094 54.96116256713867 5.03914737701416 58.99850845336914 10.00170707702637 58.99850845336914 L 86.92910766601562 58.99850845336914 M 97.00201416015625 59.99850845336914 L 10.00170707702637 59.99850845336914 C 4.487733840942383 59.99850845336914 0.001814015326090157 55.5125617980957 0.001814015326090157 49.99860382080078 L 0.001814015326090157 9.998096466064453 C 0.001814015326090157 4.484136581420898 4.487733840942383 -0.001796523225493729 10.00170707702637 -0.001796523225493729 L 97.00201416015625 -0.001796523225493729 L 97.00201416015625 1.998910188674927 L 87.00212097167969 1.998910188674927 C 83.79672241210938 1.998910188674927 80.78326416015625 3.247043371200562 78.51690673828125 5.513403415679932 C 76.25054931640625 7.779763698577881 75.00241088867188 10.79320335388184 75.00241088867188 13.99860382080078 L 75.00241088867188 45.99811172485352 C 75.00241088867188 49.20356369018555 76.25054931640625 52.2171630859375 78.51690673828125 54.48374938964844 C 80.78331756591797 56.75040435791016 83.79676055908203 57.99871063232422 87.00212097167969 57.99871063232422 L 97.00201416015625 57.99871063232422 L 97.00201416015625 59.99850845336914 Z" stroke="none" fill="#b18093" />
        </g>
        <text transform="translate(84.002 24.002)" fill="#fff" fontSize="12" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="-54" y="0">BANKER</tspan></text>
        <text transform="translate(84.002 44.002)" fill="#fc0" fontSize="12" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="-41" y="0">{odd[Prediction.Banker]}</tspan></text>
      </g>
      <g transform="translate(-15.996 -258.999)" onClick={()=> selectBoard(Prediction.BankerPair, odd[Prediction.BankerPair])}
      className={classNames("banker board-svg-item", {
        disabled: disabledAll,
        active: selectedBoard.board === Prediction.BankerPair })}>
        <g transform="translate(373.998 356.999) rotate(180)" fill="url(#small-linear-gradient-3)">
          <path d="M 74.50240325927734 47.49880981445312 L 24.00209808349609 47.49880981445312 C 18.76384544372559 47.49880981445312 14.50220489501953 43.2371711730957 14.50220489501953 37.9989013671875 L 14.50220489501953 9.998103141784668 C 14.50220489501953 4.759849548339844 18.76384544372559 0.4982093870639801 24.00209808349609 0.4982093870639801 L 113.5020980834961 0.4982093870639801 L 113.5020980834961 1.498902678489685 L 87.00209808349609 1.498902678489685 C 83.66312408447266 1.498902678489685 80.52413940429688 2.799049377441406 78.16333770751953 5.159862518310547 C 75.80255126953125 7.520649433135986 74.50240325927734 10.65964984893799 74.50240325927734 13.99860954284668 L 74.50240325927734 47.49880981445312 Z" stroke="none" />
          <path d="M 74.00240325927734 46.99880981445312 L 74.00240325927734 13.99860954284668 C 74.00240325927734 10.52608966827393 75.35456848144531 7.261529445648193 77.80979156494141 4.806302547454834 C 80.2650146484375 2.351062774658203 83.52957916259766 0.9989027380943298 87.00209808349609 0.9989027380943298 L 113.0020980834961 0.9982094168663025 L 24.00209808349609 0.9982094168663025 C 19.03953742980957 0.9982094168663025 15.00220489501953 5.035542964935303 15.00220489501953 9.998103141784668 L 15.00220489501953 37.9989013671875 C 15.00220489501953 42.96146392822266 19.03953742980957 46.99880981445312 24.00209808349609 46.99880981445312 L 74.00240325927734 46.99880981445312 M 75.00240325927734 47.99880981445312 L 24.00209808349609 47.99880981445312 C 18.48813819885254 47.99880981445312 14.00220489501953 43.51286315917969 14.00220489501953 37.9989013671875 L 14.00220489501953 9.998103141784668 C 14.00220489501953 4.484142780303955 18.48813819885254 -0.001790601992979646 24.00209808349609 -0.001790601992979646 L 114.0020980834961 -0.001790601992979646 L 114.0020980834961 1.998902678489685 L 87.00209808349609 1.998902678489685 C 83.79669952392578 1.998902678489685 80.78325653076172 3.247035980224609 78.51689910888672 5.513409614562988 C 76.25054931640625 7.77975606918335 75.00240325927734 10.7931957244873 75.00240325927734 13.99860954284668 L 75.00240325927734 47.99880981445312 Z" stroke="none" fill="#b18093" />
        </g>
        <text transform="translate(352 323.002)" fill="#fff" fontSize="10" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="-45" y="0">BANKER</tspan><tspan x="-25" y="12">PAIR</tspan></text>
        <text transform="translate(352 351.002)" fill="#fc0" fontSize="10" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="-21" y="0">{odd[Prediction.BankerPair]}</tspan></text>
      </g>
      <g transform="translate(0 50)" onClick={()=> selectBoard(Prediction.PlayerPair, odd[Prediction.PlayerPair])}
      className={classNames("player board-svg-item", {
        disabled: disabledAll,
        active: selectedBoard.board === Prediction.PlayerPair })}>
        <g transform="translate(0 0)" fill="url(#small-linear-gradient-2)">
          <path d="M 99.49990081787109 47.50060653686523 L 9.999897003173828 47.50060653686523 C 4.761643886566162 47.50060653686523 0.5000037550926208 43.23896789550781 0.5000037550926208 38.00070190429688 L 0.5000037550926208 9.99990177154541 C 0.5000037550926208 4.761648178100586 4.761643886566162 0.5000080466270447 9.999897003173828 0.5000080466270447 L 60.50020217895508 0.5000080466270447 L 60.50020217895508 34.00020980834961 C 60.50020217895508 37.33920669555664 61.80035018920898 40.47835540771484 64.16112518310547 42.83938217163086 C 66.52198028564453 45.20048904418945 69.66098785400391 46.50080871582031 72.99990081787109 46.50080871582031 L 99.49990081787109 46.50080871582031 L 99.49990081787109 47.50060653686523 Z" stroke="none" />
          <path d="M 72.92688751220703 47.00060653686523 C 69.48170471191406 46.98176956176758 66.24561309814453 45.63124084472656 63.80754852294922 43.19291305541992 C 61.35235214233398 40.73744964599609 60.00020217895508 37.47275543212891 60.00020217895508 34.00020980834961 L 60.00020217895508 1.0000079870224 L 9.999897003173828 1.0000079870224 C 5.037337303161621 1.0000079870224 1.000003695487976 5.037341594696045 1.000003695487976 9.99990177154541 L 1.000003695487976 38.00070190429688 C 1.000003695487976 42.96326065063477 5.037337303161621 47.00060653686523 9.999897003173828 47.00060653686523 L 72.92688751220703 47.00060653686523 M 99.99990081787109 48.00060653686523 L 9.999897003173828 48.00060653686523 C 4.485937118530273 48.00060653686523 3.742268972928287e-06 43.5146598815918 3.742268972928287e-06 38.00070190429688 L 3.742268972928287e-06 9.99990177154541 C 3.742268972928287e-06 4.485941410064697 4.485937118530273 8.027750482142437e-06 9.999897003173828 8.027750482142437e-06 L 61.00020217895508 8.027750482142437e-06 L 61.00020217895508 34.00020980834961 C 61.00020217895508 37.20564651489258 62.24833679199219 40.2192497253418 64.51469421386719 42.48584747314453 C 66.78111267089844 44.75250244140625 69.79454803466797 46.00080871582031 72.99990081787109 46.00080871582031 L 99.99990081787109 46.00080871582031 L 99.99990081787109 48.00060653686523 Z" stroke="none" fill="#7bb1a7" />
        </g>
        <text transform="translate(7.996 14.002)" fill="#fff" fontSize="10" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="0" y="0">PLAYER</tspan><tspan x="0" y="12">PAIR</tspan></text>
        <text transform="translate(7.996 42.002)" fill="#fc0" fontSize="10" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="0" y="0">{odd[Prediction.PlayerPair]}</tspan></text>
      </g>
      <g transform="translate(-15.996 -256.999)" onClick={()=> selectBoard(Prediction.PerfectPair, odd[Prediction.PerfectPair])}
      className={classNames("perfect board-svg-item", {
        disabled: disabledAll,
        active: selectedBoard.board === Prediction.PerfectPair })}>
        <g transform="translate(15.996 257)" fill="url(#small-linear-gradient-6)">
          <path d="M 60.50020217895508 47.50060272216797 L 9.999900817871094 47.50060272216797 C 4.761641025543213 47.50060272216797 0.5000012516975403 43.23855972290039 0.5000012516975403 37.99980163574219 L 0.5000012516975403 9.99990177154541 C 0.5000012516975403 4.761641502380371 4.761641025543213 0.5000013709068298 9.999900817871094 0.5000013709068298 L 55.99980163574219 0.5000013709068298 C 61.23805999755859 0.5000013709068298 65.49970245361328 4.761641502380371 65.49970245361328 9.99990177154541 L 65.49970245361328 33.99958038330078 C 64.01541137695312 35.11506271362305 62.78147888183594 36.56440353393555 61.92154312133789 38.20532989501953 C 60.99169158935547 39.97962188720703 60.50020217895508 41.98342132568359 60.50020217895508 44.00010299682617 L 60.50020217895508 47.50060272216797 Z" stroke="none" />
          <path d="M 60.00020217895508 47.00060272216797 L 60.00020217895508 44.00010299682617 C 60.00020217895508 41.90291213989258 60.51145172119141 39.81885147094727 61.47867202758789 37.97323989868164 C 62.33409118652344 36.34092712402344 63.54519271850586 34.89106750488281 64.99970245361328 33.75295639038086 L 64.99970245361328 9.99990177154541 C 64.99970245361328 5.037341594696045 60.96236038208008 1.000001311302185 55.99980163574219 1.000001311302185 L 9.999900817871094 1.000001311302185 C 5.037341117858887 1.000001311302185 1.000001192092896 5.037341594696045 1.000001192092896 9.99990177154541 L 1.000001192092896 37.99980163574219 C 1.000001192092896 42.96286010742188 5.037341117858887 47.00060272216797 9.999900817871094 47.00060272216797 L 60.00020217895508 47.00060272216797 M 61.00020217895508 48.00060272216797 L 9.999900817871094 48.00060272216797 C 4.485941410064697 48.00060272216797 1.226806602971919e-06 43.51426315307617 1.226806602971919e-06 37.99980163574219 L 1.226806602971919e-06 9.99990177154541 C 1.226806602971919e-06 4.485941410064697 4.485941410064697 1.361083945994324e-06 9.999900817871094 1.361083945994324e-06 L 55.99980163574219 1.361083945994324e-06 C 61.51375961303711 1.361083945994324e-06 65.99970245361328 4.485941410064697 65.99970245361328 9.99990177154541 L 65.99970245361328 34.25251007080078 C 64.48538208007812 35.34181213378906 63.22846221923828 36.78865051269531 62.36441040039062 38.43741989135742 C 61.47193145751953 40.14041137695312 61.00020217895508 42.06396102905273 61.00020217895508 44.00010299682617 L 61.00020217895508 48.00060272216797 Z" stroke="none" fill="#89929b" />
        </g>
        <text transform="translate(24 271.002)" fill="#fff" fontSize="10" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="0" y="0">PERFECT</tspan><tspan x="0" y="12">PAIR</tspan></text>
        <text transform="translate(24 299.002)" fill="#fc0" fontSize="10" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="0" y="0">{odd[Prediction.PerfectPair]}</tspan></text>
      </g>
      <g transform="translate(278 0)" onClick={()=> selectBoard(Prediction.EitherPair, odd[Prediction.EitherPair])}
      className={classNames("perfect board-svg-item", {
        disabled: disabledAll,
        active: selectedBoard.board === Prediction.EitherPair })}>
        <g transform="translate(66 48) rotate(180)" fill="url(#small-linear-gradient-7)">
          <path d="M 55.99980163574219 47.50060272216797 L 9.99990177154541 47.50060272216797 C 4.761641502380371 47.50060272216797 0.5000013709068298 43.23896026611328 0.5000013709068298 38.00070190429688 L 0.5000013709068298 10.00080108642578 C 0.5000013709068298 4.762041568756104 4.761641502380371 0.5000013709068298 9.99990177154541 0.5000013709068298 L 60.50020217895508 0.5000013709068298 L 60.50020217895508 4.000501155853271 C 60.50020217895508 6.01737117767334 60.99178314208984 8.021341323852539 61.92180252075195 9.795781135559082 C 62.78189086914062 11.43682289123535 64.01604461669922 12.88622093200684 65.50060272216797 14.00168037414551 L 65.50060272216797 38.00070190429688 C 65.50060272216797 43.23896026611328 61.23855972290039 47.50060272216797 55.99980163574219 47.50060272216797 Z" stroke="none" />
          <path d="M 55.99980163574219 47.00060272216797 C 60.96286010742188 47.00060272216797 65.00060272216797 42.96326065063477 65.00060272216797 38.00070190429688 L 65.00060272216797 14.24833297729492 C 63.54582595825195 13.11022186279297 62.33451843261719 11.66031455993652 61.47894287109375 10.02789115905762 C 60.51153945922852 8.182131767272949 60.00020217895508 6.097891330718994 60.00020217895508 4.000501155853271 L 60.00020217895508 1.000001311302185 L 9.99990177154541 1.000001311302185 C 5.037341594696045 1.000001311302185 1.000001311302185 5.037741184234619 1.000001311302185 10.00080108642578 L 1.000001311302185 38.00070190429688 C 1.000001311302185 42.96326065063477 5.037341594696045 47.00060272216797 9.99990177154541 47.00060272216797 L 55.99980163574219 47.00060272216797 M 55.99980163574219 48.00060272216797 L 9.99990177154541 48.00060272216797 C 4.485941410064697 48.00060272216797 1.361083945994324e-06 43.5146598815918 1.361083945994324e-06 38.00070190429688 L 1.361083945994324e-06 10.00080108642578 C 1.361083945994324e-06 4.48634147644043 4.485941410064697 1.361083945994324e-06 9.99990177154541 1.361083945994324e-06 L 61.00020217895508 1.361083945994324e-06 L 61.00020217895508 4.000501155853271 C 61.00020217895508 5.936841487884521 61.47202301025391 7.860551357269287 62.36466217041016 9.563671112060547 C 63.22872161865234 11.21228122711182 64.48600769042969 12.6594409942627 66.00060272216797 13.7487211227417 L 66.00060272216797 38.00070190429688 C 66.00060272216797 43.5146598815918 61.51426315307617 48.00060272216797 55.99980163574219 48.00060272216797 Z" stroke="none" fill="#89929b" />
        </g>
        <text transform="translate(57.996 14.002)" fill="#fff" fontSize="10" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="-40" y="0">EITHER</tspan><tspan x="-25" y="12">PAIR</tspan></text>
        <text transform="translate(57.996 42.002)" fill="#fc0" fontSize="10" fontFamily="SFProText-Heavy, SF Pro Text" fontWeight="800" letterSpacing="0.04em">
          <tspan x="-16" y="0">{odd[Prediction.EitherPair]}</tspan></text>
      </g>
    </svg>
  )
}

export default SmallBoard