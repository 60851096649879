import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import sounList from "./soundList";
import {setSoundKey} from "@actions/soundMusicAction"
import useTaskQueue from "../../hook/useQueue";
function BackgroundMusic({backgroundMusic, soundKey, sound, setSoundKey, volumeRange}) {
    const [startQueue, setStartQueue] =useState(false);
    const [allowPlay, setAllowPlay] = useState(false)
    const bgSoundRef = useRef(null);
    const actionSoundRef =  useRef(null);

    const {addTask, tasks} = useTaskQueue({shouldProcess: startQueue})

    useEffect(() => {
        function allowPlayMethod() {
            setAllowPlay(true)
        }
        window.addEventListener("click", allowPlayMethod)
        return () => {
            window.removeEventListener("click", allowPlayMethod)
        }
    }, [])

    useEffect(() => {
        if ( bgSoundRef.current) {
            bgSoundRef.current.volume = +volumeRange;
        }
       
    }, [volumeRange])

    useEffect(() => {
        if (allowPlay) {
            if (backgroundMusic) {
                suffleSond()
            } else {
                bgSoundRef.current.pause()
            }
        }
    }, [backgroundMusic, allowPlay]);

    useEffect(() => {
        if (soundKey && sound && allowPlay) {
            if (soundKey === "addBetSound"|| soundKey === "timerLastSecondsSound") {
                const s = new Audio(sounList[soundKey])
                s.play()
            } else {
                setStartQueue(true)
                addTask(() => playQueeSound(soundKey))
            }
           
        }
        setSoundKey("")
    }, [soundKey, sound, allowPlay])

    useEffect(() => {
        if (!tasks.length) {
            setStartQueue(false)
        }
    }, [tasks])



    const suffleSond = () => {
        var rand = Math.floor(Math.random() * sounList.backgroundMusic.length);
        bgSoundRef.current.src = sounList.backgroundMusic[rand];
        bgSoundRef.current.volume = +volumeRange;
        bgSoundRef.current.play()
    }

    const playQueeSound = (key) => {
        return new Promise(function(resolve, reject) {   
            if(sound && allowPlay  && actionSoundRef.current) {
                actionSoundRef.current.src = sounList[key];
                actionSoundRef.current.currentTime = 0
                actionSoundRef.current.autoplay = true;
                actionSoundRef.current.play()                    
                                      
                actionSoundRef.current.onerror = reject;                     
                actionSoundRef.current.onended = resolve;      
            }  else {
                reject()
            }          
   
        });
    }

    return ( <>
    <audio ref={bgSoundRef}  onEnded={suffleSond} />
    <audio ref={actionSoundRef}/>
    </>)
}

const mapStateToProps = (state) => {
    return {
        backgroundMusic: state.backgroundMusic,
        soundKey: state.soundKey,
        sound: state.sound,
        volumeRange: state.volume
    }
}

const mapDispatchToProps = {
    setSoundKey
}

export default connect(mapStateToProps,mapDispatchToProps)(BackgroundMusic)