import {CONNECT_TO_SOCKET, SET_INACTIVITY} from "@actions/actionTypes";

export default function socketMiddleware(socket) {
    return ({dispatch, getState }) => next => (action) => {
        if (typeof action === 'function') return next(action);

        if (action.type === CONNECT_TO_SOCKET) {
            socket.connect(action.session)
            return;
        }

        if (action.type === SET_INACTIVITY) {
            if (action.payload) {
                socket.close({code: "inactivity"})
            } else {
                socket.connect(getState().globalInfo.session)
            }
        }

        const {eventOn, eventEmit, handle, emitData} = action;

        if (eventOn && typeof eventOn === "string") {
            return socket.on(eventOn, handle);
        } else if (eventEmit && typeof eventEmit === "string") {
             socket.emit(eventEmit, emitData);
        }
        
        return next(action);
    };
}
