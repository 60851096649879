import React from "react";
import {connect} from "react-redux";
import {setSound} from "@actions/soundMusicAction";
import {FormattedMessage} from "react-intl";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";
import {SoundOnIcon, SoundOffIcon} from "@resource";

function GameSound({setSound, soundOnOff}) {
    return (
        <li className={classNames(`template-button-item hybrid-item`)} onClick={() => setSound(!soundOnOff)}>
            <ToolTip title={<FormattedMessage id={"allow_sound"}/>} position={"bottom"}>
                {soundOnOff ? <SoundOnIcon /> : <SoundOffIcon />}
            </ToolTip>
        </li>
    )
}

function mapStateToProps(state) {
    return {
        soundOnOff: state.sound
    }
}

const mapDispatchToProps = {
    setSound
}

export default connect(mapStateToProps, mapDispatchToProps)(GameSound)