import React from "react";
import classNames from "classnames";
import {gameHistory} from "@constants/Enums";

function GameHistoryIcon({won, type, active}) {

    return (
        <div className={classNames("game-history-icon", type, gameHistory[won], {active: active})}>
            { won === gameHistory.Banker && "B" }
            { won === gameHistory.Player && "P" }
            { won === gameHistory.Tie && "T" }
        </div>
    )
}

export default GameHistoryIcon