import React from "react";
import {connect} from "react-redux";
import {setVolum} from "@actions/soundMusicAction";

function VolumController({setVolum, volume}) {

    const handleChangeVolum = (value) => {
        setVolum(value)
    }

    return ( 
    <div className="settings-box-sound-range" onClick={(e) =>  e.stopPropagation()}>
        <input type="range" min={0} max={1} step={0.001} onChange={({target}) => handleChangeVolum(target.value)} defaultValue={volume}/>
    </div>)
}


function mapStateToProps(state) {
    return {
        volume: state.volume
    }
}


const mapDispatchToProps = {
    setVolum,
}

export default connect(mapStateToProps, mapDispatchToProps)(VolumController)