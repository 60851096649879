import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from "./redux/configureStore";
import App from './App';
import {Provider as ReduxProvider} from "react-redux";
import {DefaultLanguage, DefaultLanguageCode} from '@constants/Enums';
import {
    isIOS,
    isMobile,
    isTablet,
    isChrome,
    isMobileOnly
} from "react-device-detect";
import translations from './translations';
// const locale = 'en' // en;
// const localeId = 42 // en;

document.body.classList.add(isIOS? 'ios' : 'not-ios');
document.body.classList.add(isMobile? 'mobile' : 'not-mobile');
document.body.classList.add(isMobileOnly? 'mobile-only' : 'not-mobile-only');
document.body.classList.add(isTablet? 'tablet' : 'not-tablet');
document.body.classList.add(isChrome? 'chrome' : 'not-chrome');
import {LanguageCode} from "./constants/Enums";
import {PostResource} from "./utils/HttpUtil";
const store = configureStore();

// fetch(`${process.env.REACT_APP_API_PATH}/rate/translations?langid=${DefaultLanguageCode}`)
//     .then((res) => {
//         if (res.status >= 400) {
//             throw new Error('Bad response from server');
//         }
//
//         // return res.json();
//     })
//     .then((localeData) => {
//         // addLocaleData(window.ReactIntlLocaleData[locale]);
//         let m = {};
//         // //
//         // localeData.data.items.forEach(el => {
//         //     m[el.key] = el.value;
//         //     if (el.icon) {
//         //         m[el.key + 'icon'] = el.icon;
//         //     }
//         // });
//
//         ReactDOM.render(
//             <ReduxProvider store={store}>
//                 <App locale={DefaultLanguage} messages={translations}/>
//             </ReduxProvider>,
//             document.getElementById('root')
//         );
//     }).catch((error) => {
//     console.error(error);
// });


ReactDOM.render(
    <ReduxProvider store={store}>
        <App locale={DefaultLanguage} messages={translations}/>
    </ReduxProvider>,
    document.getElementById('root')
);