import React, { useEffect } from "react";
import { connect } from "react-redux";
import {subscribeCards} from "@actions/cardsAction";
import CardList from "./CardsList";
import {FormattedMessage} from "react-intl";

function Cards({playerCards, bankerCards, subscribeCards}) {

    useEffect(() => {
        subscribeCards();
    }, [])

    return (
        <div className="cards">
            
                <CardList cardList={playerCards} sideText={<FormattedMessage id="player"/>}  showPoint={playerCards.cards.length || bankerCards.cards.length} />
                <div className="line"></div>
                <CardList cardList={bankerCards} sideText={<FormattedMessage id="banker"/>} showPoint={playerCards.cards.length || bankerCards.cards.length}/>
            
        </div>
    )
}

function mapStateToProps(state) {
    return {
        playerCards: state.playerCards,
        bankerCards: state.bankerCards
    }
}

const mapDispatchToProps = {
    subscribeCards
}


export default connect(mapStateToProps, mapDispatchToProps)(Cards);