import React from "react";

export default function SoundOnIcon() {
    return (
       <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
        <defs>
            <filter id="SoundOnIcona" x="21.158" y="0.715" filterUnits="userSpaceOnUse">
                <feOffset dy="2" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="2" result="b"/>
                <feFlood/>
                <feComposite operator="in" in2="b"/>
                <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="SoundOnIconc" x="17.724" y="3.784" filterUnits="userSpaceOnUse">
                <feOffset dy="2" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="2" result="d"/>
                <feFlood/><feComposite operator="in" in2="d"/>
                <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="SoundOnIcone" x="0" y="4.186" filterUnits="userSpaceOnUse">
                <feOffset dy="2" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="2" result="f"/>
                <feFlood/>
                <feComposite operator="in" in2="f"/>
                <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="SoundOnIcong" x="7.615" y="0" filterUnits="userSpaceOnUse">
                <feOffset dy="2" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="2" result="h"/>
                <feFlood/>
                <feComposite operator="in" in2="h"/>
                <feComposite in="SourceGraphic"/>
            </filter>
        </defs>
        <g transform="translate(12 13)">
            <g transform="matrix(1, 0, 0, 1, -6.5, -4.52)" filter="url(#SoundOnIcona)">
                <path d="M21.5,20.569a.891.891,0,0,0,.688-.262,12.824,12.824,0,0,0,3.854-9.248A12.994,12.994,0,0,0,22.191,1.81a.954.954,0,0,0-1.437,0,1.149,1.149,0,0,0,.017,1.442,10.807,10.807,0,0,1,3.261,7.8,11,11,0,0,1-3.275,7.814,1.148,1.148,0,0,0,.023,1.458,1.12,1.12,0,0,0,.721.238" transform="translate(7.24 3.75)" fill="#fff" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" fillRule="evenodd"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, -6.5, -4.52)" filter="url(#SoundOnIconc)">
                <path d="M18.238,17.388a.984.984,0,0,0,.689-.261,8.655,8.655,0,0,0,2.6-6.175,8.776,8.776,0,0,0-2.588-6.27,1.152,1.152,0,0,0-1.454.007,1.1,1.1,0,0,0,.015,1.472,6.722,6.722,0,0,1,0,9.531,1.04,1.04,0,0,0,0,1.433,1.1,1.1,0,0,0,.744.263" transform="translate(7.12 3.86)" fill="#fff" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" fillRule="evenodd"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, -6.5, -4.52)" filter="url(#SoundOnIcone)">
                <path d="M0,15.9A1.215,1.215,0,0,0,1.273,17.01h4.17V4.815H1.273A1.215,1.215,0,0,0,0,5.93Z" transform="translate(6.5 3.87)" fill="#fff" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" fillRule="evenodd"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, -6.5, -4.52)" filter="url(#SoundOnIcong)"><path d="M13.7.96a1.408,1.408,0,0,0-1.427.07L7.351,4.354V17.761l4.926,3.325a1.406,1.406,0,0,0,1.423.069,1.036,1.036,0,0,0,.576-.926V1.886A1.036,1.036,0,0,0,13.7.96" transform="translate(6.76 3.73)" fill="#fff" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" fillRule="evenodd"/>
            </g>
        </g>
    </svg>
    )
}