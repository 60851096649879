import React, {useState} from "react";
import Rules from "@components/rules";
import {FormattedMessage} from "react-intl";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";
import {InfoIcon} from "@resource";
import { useEffect } from "react";

function GameRules({mobileActions,setMobileActions}) {
    const [rules, setRules] = useState(false);

    useEffect(() => {
        if (mobileActions === "rules" && !rules) {
            setRules(true)
        }
        if (!rules) {
            setMobileActions("")
        }

    },[rules,mobileActions])

    return (
        <>
        <li className={classNames(`template-button-item`, {active: rules})} onClick={() => setRules(prev => !prev)}>
            <ToolTip title={<FormattedMessage id={"game_rules"}/>} position={"bottom"}>
                {<InfoIcon/>}
            </ToolTip>
        </li>
             <Rules handleCancel={() => setRules(false)} show={rules} />
        </>
    )
}

export default GameRules