import React from "react";

export default function musicOnIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <defs>
                <filter id="musicOnIcon" x="0" y="0" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="b"/>
                    <feFlood/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#musicOnIcon)">
                <path d="M20.71,0,4.914,1.764V14.616a.792.792,0,0,1-.326.655.825.825,0,0,1-.7.159l-.467,0a3.424,3.424,0,0,0,0,6.848,3.479,3.479,0,0,0,3.424-3.306V6.133A.864.864,0,0,1,7.6,5.274L17.81,4.139a.805.805,0,0,1,.68.161l.037.037A1.073,1.073,0,0,1,18.778,5v8.023a.993.993,0,0,1-.31.681.907.907,0,0,1-.683.174,2.6,2.6,0,0,0-.541-.036,3.424,3.424,0,1,0,3.467,3.541Z" transform="translate(13 12)" fill="#fff" fillRule="evenodd"/>
            </g>
        </svg>
    )
}