import React, {useState} from "react";
import RightMenu from "@components/rightMenu";
import {BurgerMenuIcon} from "@resource";
import {FormattedMessage} from "react-intl";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";

function BurgerMenu({setMobileActions}) {
    const [rightMenu, setRightMenu] = useState(false)
    return (
        <>
            <li className={classNames(`template-button-item mobile-item`, {active: rightMenu})} onClick={() =>setRightMenu(prev => !prev)}>
                <ToolTip title={<FormattedMessage id={"menu"}/>} position={"bottom"}>
                    {<BurgerMenuIcon />}
                </ToolTip>
            </li>
            <RightMenu handleCancel={() => setRightMenu(false)}
                    submitMenu={setMobileActions}
                    show={rightMenu}/>
        </>
        
    )
}

export default BurgerMenu