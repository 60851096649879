import { 
    SET_BACKGROUND_MUSIC, 
    SET_SOUND_ON_OFF ,
    SET_VOLUME ,
    SET_SOUND_KEY
} from "@actions/actionTypes"

export function setSound(payload) {
    return dispatch => {
        dispatch({
            type: SET_SOUND_ON_OFF,
            payload
        })
    }
}

export function setMusic(payload) {
    return dispatch => {
        dispatch({
            type: SET_BACKGROUND_MUSIC,
            payload
        })
    }
}

export function setSoundKey(payload) {
    return dispatch => {
        dispatch({
            type: SET_SOUND_KEY,
            payload
        })
    }
}

export function setVolum(payload) {
    return dispatch => {
        dispatch({
            type: SET_VOLUME,
            payload
        })
    }
}