import React, {useState} from "react";
import {connect} from "react-redux";
import BurgerMenu from "./burgerMenu";
import CloseGame from "./closeGame";
import FullScreen from "./fullScreen";
import GameMusic from "./gameMusic";
import GameRules from "./gameRules";
import GameSound from "./gameSound";
import PlayerHistory from "./playerHistory";
import SwitchView from "./switchView";


function HeaderActions() {
    const [mobileActions, setMobileActions] = useState("");   

    return (
        <div className="settings-box">
            <ul className="template-button-list">
                <FullScreen />
                <SwitchView />
                <PlayerHistory mobileActions={mobileActions} setMobileActions={setMobileActions} />
                <GameRules mobileActions={mobileActions} setMobileActions={setMobileActions} />
                <GameSound />
                <GameMusic/>
                <BurgerMenu  setMobileActions={setMobileActions}/>
                <CloseGame />
            </ul>
            
        </div>
    )
}

export default HeaderActions