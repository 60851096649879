import React from "react";
import {ModalCloseIcon} from "@resource";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

function InfoPopUp({title, description, submit, handleCancel, show, okButtontext= "yes", cancelButtontext = "no", className =""}) {
    return(
        <div className={classNames("info-popup", className, {show})}>
            <div className="info-popup-content">

                <div className="info-popup-content-header">
                    <div className="title">
                        {title}
                    </div>
                    <button className="circled-button small" onClick={handleCancel}>
                        <ModalCloseIcon id="InfoPopUp"/>
                    </button>

                </div>
                <div className="info-popup-body">
                    <div className="description">
                        {description}
                    </div>
                </div>
                <div className="info-popup-footer">
                    <button className="popup-btn" onClick={handleCancel}><FormattedMessage id={cancelButtontext}/></button>
                    <button className="popup-btn active" onClick={submit}><FormattedMessage id={okButtontext}/></button>
                </div>
            </div>
        </div>
    )
}

export default InfoPopUp