import React, {useState} from "react";
import { getLobbyUrl, isOpenedByLobby } from "@utils/HttpUtil";
import { inIframe } from "@utils/CommonUtil";
import InfoPopUp from "@components/infoPopUp";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";
import {HeaderCloseIcon} from "@resource";

function CloseGame({lobbyUrl}) {
    const [exit, setExit] = useState(false)

    const goLobby = () => {
        setExit(false);
        
        if (isOpenedByLobby() && inIframe()) {
            window.parent.postMessage("CLOSE", getLobbyUrl());
        } else {
            // top.window.location.href= lobbyUrl;
            top.window.location.href = document.referrer;
        }
    }

    return (
        <>
         <li className={classNames(`template-button-item hybrid-item`, {active: exit})} onClick={() => setExit(prev => !prev)}>
                <ToolTip title={<FormattedMessage id={"close"}/>} position={"bottom"}>
                    {<HeaderCloseIcon />}
                </ToolTip>
        </li>
        <InfoPopUp title={<FormattedMessage id="exit_to_lobby"/>}
                              show={exit}
                              description={<FormattedMessage id="are_you_sure_you_want_to_leave_the_game" />}
                              handleCancel={() => setExit(false)}
                              submit={() => goLobby()}/>
        </>
        
    )
}

function mapStateToProps(state) {
    return {
        lobbyUrl: state.globalInfo.lobbyUrl,
    }
}


export default connect(mapStateToProps, null)(CloseGame)