import React from "react";

function ActiveBetsIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20.5 22.507">
            <defs>
                <filter id="ActiveBetsIconVector" x="1.5" y="1.5" width="19" height="21.007" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_directional_right-circle_clip_group_" data-name="icon/outlined/directional/right-circle (clip group)" transform="translate(1 1)">
                <rect id="icon_outlined_directional_right-circle_style_" data-name="icon/outlined/directional/right-circle (style)" width="18" height="18" fill="none"/>
                <g id="Ellipse_2306" className="active-bets-icon-bg" data-name="Ellipse 2306" fill="rgba(29,27,45,0.9)" stroke="rgba(135,135,148,0.5)" strokeWidth="1">
                    <circle cx="9" cy="9" r="9" stroke="none"/>
                    <circle cx="9" cy="9" r="9.5" fill="none"/>
                </g>
                <g transform="matrix(1, 0, 0, 1, -1, -1)" filter="url(#ActiveBetsIconVector)">
                    <path id="Vector-2" data-name="Vector" d="M5.924,3.86.291.033A.191.191,0,0,0,.2,0,.193.193,0,0,0,.1.019.179.179,0,0,0,.027.083.164.164,0,0,0,0,.173V1.181a.676.676,0,0,0,.3.557L3.632,4,.3,6.262a.673.673,0,0,0-.3.557V7.828a.185.185,0,0,0,.291.14L5.924,4.14A.167.167,0,0,0,5.924,3.86Z" transform="translate(8 6)" fill="#fff" stroke="rgba(0,0,0,0)" strokeWidth="1"/>
                </g>
            </g>
        </svg>
    )
}

export default ActiveBetsIcon