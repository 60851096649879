import React from "react";

function ModalCloseIcon({id}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Group_1488" data-name="Group 1488"  viewBox="0 0 24 25">
            <defs>
                <filter id={`${id}VectorModalCloseIcon`} x="2" y="4" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <circle id="Ellipse_358" data-name="Ellipse 358" cx="12" cy="12" r="12" fill="none" opacity="0.2"/>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter={`url(#${id}VectorModalCloseIcon)`}>
                <path id="Vector-2" data-name="Vector" d="M4.659,4.5,7.976.181A.111.111,0,0,0,7.9,0H6.891a.2.2,0,0,0-.155.079L4,3.641,1.265.079A.2.2,0,0,0,1.109,0H.1A.111.111,0,0,0,.024.181L3.341,4.5.024,8.819A.115.115,0,0,0,0,8.875a.119.119,0,0,0,.008.061.107.107,0,0,0,.037.046A.1.1,0,0,0,.1,9H1.109a.2.2,0,0,0,.155-.079L4,5.359,6.735,8.921A.2.2,0,0,0,6.891,9H7.9a.111.111,0,0,0,.077-.181Z" transform="translate(8 8)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default ModalCloseIcon