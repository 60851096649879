import React, {useState} from "react";
import CardsListItem from "./CardsListItem";

export function CardList({cardList,  sideText}) {
    const [initialList] = useState(new Array(3).fill(""))
    return(



        <div className="cards-element">
            <div className="title">{sideText}</div>
            <ul className="cards-list">
            {initialList.map((_,index)=>(
            <CardsListItem key={index} card={cardList.cards[index]} />
       ))}
            </ul>
        </div>




        // <div className={classNames("cards-list", className)}>
        //     <div className={classNames(`cards-list-point`, {show: showPoint})}>
        //         <span className="text">
        //             {sideText} -
        //         </span>
        //         {cardList.cardsWeight}
        //     </div>
        //     {initialList.map((_,index)=>(
        //         <CardsListItem key={index} card={cardList.cards[index]} />
        //     ))}
        // </div>
    )
}

export default CardList

