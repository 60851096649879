import {SET_PLAYER_CARDS} from "@actions/actionTypes";
import initialStore from "../../initialStore";
import {CardsSuit, CardTypeMap} from "@constants/Enums";

function playerCardsReducer(state = initialStore.cards, action) {
    switch (action.type) {
        case SET_PLAYER_CARDS:
            return {
                cards: action.cards.map(card => { return {type: CardTypeMap[card.Type], suit: CardsSuit[card.Suit]}}),
                cardsWeight: action.cardsWeight
            }
        default:
            return state;
    }
}

export default playerCardsReducer