import {
    SET_SELECTED_BOARD,
    RESET_SELECTED_BOARD
} from "@actions/actionTypes";
import initialStore from "../../initialStore";

export default function selectedBoardReducer(state = initialStore.selectedBoard, action) {
    switch (action.type) {
        case SET_SELECTED_BOARD:
            return {board: action.board, odd: action.odd}
        case RESET_SELECTED_BOARD:
            return initialStore.selectedBoard
        default:
            return state;
    }
}
