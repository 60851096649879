import React from "react";

function LoadingIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 166.958 166.15">
            <defs>
                <linearGradient id="LoadingIcona" x1="0.008" y1="0.298" x2="0.257" y2="0.833" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopOpacity="0.659"/>
                    <stop offset="0.543" stopColor="#333236" stopOpacity="0.776"/><stop offset="1" stopColor="#565558"/>
                </linearGradient>
                <linearGradient id="LoadingIconb" x1="1.241" y1="0.826" x2="0.67" y2="0.164" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#565558"/><stop offset="0.321" stopColor="#58585c"/>
                    <stop offset="0.687" stopColor="#7e7d80"/><stop offset="1" stopColor="#b1b1ba"/>
                </linearGradient>
                <clipPath id="LoadingIconc"><rect width="82.029" height="122.222" fill="url(#a)"/></clipPath>
                <clipPath id="LoadingIcone"><rect width="59.692" height="121.289" fill="url(#b)"/></clipPath>
            </defs>
            <g transform="translate(-877 -457)">
                <g transform="translate(972.919 476.288) rotate(30)">
                    <g transform="translate(0 0)" clipPath="url(#LoadingIconc)">
                        <path d="M20.918,0A60.993,60.993,0,0,0,0,3.674V22.143q1.816-.978,3.712-1.781a44.136,44.136,0,0,1,41.916,4.074A44.552,44.552,0,0,1,57.593,36.4a44.18,44.18,0,0,1,4.074,7.5,44.418,44.418,0,0,1,0,34.412,44.18,44.18,0,0,1-4.074,7.5A44.553,44.553,0,0,1,45.628,97.786,44.319,44.319,0,0,1,3.712,101.86q-1.892-.8-3.712-1.781v18.469A61.118,61.118,0,1,0,20.918,0" transform="translate(0 0)" fill="url(#LoadingIcona)"/>
                    </g>
                </g>
                <g transform="translate(937.644 457) rotate(30)">
                    <g transform="translate(0 0)" clipPath="url(#LoadingIcone)">
                        <path d="M59.692,104.388A43.9,43.9,0,0,1,36.4,96.87,44.394,44.394,0,0,1,20.362,77.4a44.136,44.136,0,0,1,4.074-41.916A44.4,44.4,0,0,1,43.905,19.446a43.685,43.685,0,0,1,8.928-2.7A8.446,8.446,0,0,0,49.7.149a61.117,61.117,0,0,0,10,121.14Z" transform="translate(0 0)" fill="url(#LoadingIconb)"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default LoadingIcon