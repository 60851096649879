import {
    SET_TIME_TICKER,
    SUB_TIME_TICKER,
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export function subscribeTimeTicker() {
    return dispatch => {
        dispatch({
            eventOn: SUB_TIME_TICKER,
            handle: (data) => {
                dispatch({type: SET_TIME_TICKER, ticker: data});
            }
        })
    }
}

export function resetTimeTicker() {
    return dispatch => {
        dispatch({
            type: SET_TIME_TICKER,
            ticker:  {
                Start: initialStore.timeTicker.start,
                End: initialStore.timeTicker.end
            }
        })
    }
}