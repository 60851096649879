import React from "react";

export default function SoundOnIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <defs>
                <filter id="SoundOnIcona" x="0" y="0" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="b"/>
                    <feFlood/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#SoundOnIcona)">
                <path d="M14.412,15.76v5.3a1,1,0,0,1-.556.895,1.357,1.357,0,0,1-1.374-.068L7.727,18.677v-9.6l-2.1-2.1V18.093H1.6A1.174,1.174,0,0,1,.375,17.016V7.4A1.173,1.173,0,0,1,1.6,6.321H4.973L0,1.347,1.347,0,25.5,24.149,24.149,25.5Zm8.032,2.422A10.56,10.56,0,0,0,21.137,4.7a1.108,1.108,0,0,1-.015-1.392.921.921,0,0,1,1.387,0,12.543,12.543,0,0,1,3.717,8.928,12.413,12.413,0,0,1-2.378,7.354ZM19.166,14.9a6.427,6.427,0,0,0-1.306-7.3,1.055,1.055,0,0,1-.014-1.421,1.112,1.112,0,0,1,1.4-.007,8.467,8.467,0,0,1,2.5,6.052,8.337,8.337,0,0,1-1.134,4.122ZM9.083,4.82l3.4-2.293a1.358,1.358,0,0,1,1.377-.067,1,1,0,0,1,.556.894v6.8Z" transform="translate(12 10)" fill="#fff" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
        </svg>
    )
}