import { SET_VOLUME, SET_BACKGROUND_MUSIC } from "@actions/actionTypes"

export default function backgroundMusicVolumReducer(state = 0.5, action) {
    switch (action.type) {
        case SET_VOLUME:
            return action.payload
        case SET_BACKGROUND_MUSIC:
            return action.payload ? 0.5 : state
        default:
            return state;
    }
}