import React, {useEffect, useState} from "react";
import {gameHistory} from "@constants/Enums";
import {HistoryTotalIcon} from "@resource";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";

function GameHistoryHeader({history}) {
    const [total, setTotal] = useState(0)
    const [winBanker, setWinBanker] = useState(0)
    const [winPlayer, setWinPlayer] = useState(0)
    const [winTie, setWinTie] = useState(0)
    useEffect(() => {
        let winBanker = 0,
            winPlayer = 0,
            winTie = 0;
        history.forEach(item => {
            if (item.Won === gameHistory.Banker) {
                winBanker++
            }

            if (item.Won === gameHistory.Player) {
                winPlayer++
            }

            if (item.Won === gameHistory.Tie) {
                winTie++
            }
        })
        setTotal(history.length);
        setWinTie(winTie);
        setWinBanker(winBanker);
        setWinPlayer(winPlayer);
    }, [history])
    return (
        <div className="game-history-header">
            <ul className="list">
                <li className="list-item">
                   <div className="icon"><HistoryTotalIcon /></div> {total}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.Banker} type="small"/> {winBanker}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.Player} type="small"/> {winPlayer}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.Tie} type="small"/> {winTie}
                </li>
            </ul>
        </div>
    )
}

export default GameHistoryHeader