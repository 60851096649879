export default {
    addBetSound: `${process.env.REACT_APP_SOUNDS_BASE_URL}systemsound/addBet.wav`,
    noMoreBetsSound: `${process.env.REACT_APP_SOUNDS_BASE_URL}systemsound/noMoreBets.wav`,
    timerLastSecondsSound:  `${process.env.REACT_APP_SOUNDS_BASE_URL}systemsound/timerLastSeconds.wav`,
    welcomeSound: `${process.env.REACT_APP_SOUNDS_BASE_URL}systemsound/welcome.wav`,
    winnerSound: `${process.env.REACT_APP_SOUNDS_BASE_URL}systemsound/winner.wav`,
    placeBetsSound: `${process.env.REACT_APP_SOUNDS_BASE_URL}systemsound/placeBets.wav`,
    '1clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/1clubs.wav`,
    '1diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/1diamonds.wav`,
    '1hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/1hearts.wav`,
    '1spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/1spades.wav`,
    '2clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/2clubs.wav`,
    '2diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/2diamonds.wav`,
    '2hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/2hearts.wav`,
    '2spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/2spades.wav`,
    '3clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/3clubs.wav`,
    '3diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/3diamonds.wav`,
    '3hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/3hearts.wav`,
    '3spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/3spades.wav`,
    '4clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/4clubs.wav`,
    '4diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/4diamonds.wav`,
    '4hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/4hearts.wav`,
    '4spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/4spades.wav`,
    '5clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/5clubs.wav`,
    '5diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/5diamonds.wav`,
    '5hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/5hearts.wav`,
    '5spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/5spades.wav`,
    '6clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/6clubs.wav`,
    '6diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/6diamonds.wav`,
    '6hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/6hearts.wav`,
    '6spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/6spades.wav`,
    '7clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/7clubs.wav`,
    '7diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/7diamonds.wav`,
    '7hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/7hearts.wav`,
    '7spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/7spades.wav`,
    '8clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/8clubs.wav`,
    '8diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/8diamonds.wav`,
    '8hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/8hearts.wav`,
    '8spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/8spades.wav`,
    '9clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/9clubs.wav`,
    '9diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/9diamonds.wav`,
    '9hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/9hearts.wav`,
    '9spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/9spades.wav`,
    '10clubs': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/10clubs.wav`,
    '10diamonds': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/10diamonds.wav`,
    '10hearts': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/10hearts.wav`,
    '10spades': `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/10spades.wav`,
    Aclubs: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Aclubs.wav`,
    Adiamonds: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Adiamonds.wav`,
    Ahearts: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Ahearts.wav`,
    Aspades: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Aspades.wav`,
    Jclubs: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Jclubs.wav`,
    Jdiamonds: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Jdiamonds.wav`,
    Jhearts: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Jhearts.wav`,
    Jspades: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Jspades.wav`,
    Kclubs: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Kclubs.wav`,
    Kdiamonds: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Kdiamonds.wav`,
    Khearts: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Khearts.wav`,
    Kspades: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Kspades.wav`,
    Qclubs: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Qclubs.wav`,
    Qdiamonds: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Qdiamonds.wav`,
    Qhearts: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Qhearts.wav`,
    Qspades: `${process.env.REACT_APP_SOUNDS_BASE_URL}cards/Qspades.wav`,
    backgroundMusic: [
        `${process.env.REACT_APP_SOUNDS_BASE_URL}tracks/track1.mp3`,
        `${process.env.REACT_APP_SOUNDS_BASE_URL}tracks/track2.mp3`,
        `${process.env.REACT_APP_SOUNDS_BASE_URL}tracks/track3.mp3`,
        `${process.env.REACT_APP_SOUNDS_BASE_URL}tracks/track4.mp3`,
        `${process.env.REACT_APP_SOUNDS_BASE_URL}tracks/track5.mp3`,
    ]
   
}