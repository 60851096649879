import React from "react";

function BurgerMenuIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 34 34">
            <defs>
                <filter id="BurgerMenuVector" x="4" y="7" width="26" height="24" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Component_304_10" data-name="Component 304 – 10" transform="translate(1 1)">
                <g id="Group_619" data-name="Group 619" transform="translate(8 8)">
                    <rect id="Rectangle_695" data-name="Rectangle 695" width="16" height="16" fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, -9, -9)" filter="url(#BurgerMenuVector)">
                        <path id="Vector-2" data-name="Vector" d="M13.86,12H.14A.138.138,0,0,1,0,11.864V10.773a.138.138,0,0,1,.14-.136H13.86a.138.138,0,0,1,.14.136v1.091A.138.138,0,0,1,13.86,12Zm0-5.318H.14A.138.138,0,0,1,0,6.546V5.454a.138.138,0,0,1,.14-.136H13.86a.138.138,0,0,1,.14.136V6.546A.138.138,0,0,1,13.86,6.682Zm0-5.318H.14A.138.138,0,0,1,0,1.227V.136A.138.138,0,0,1,.14,0H13.86A.138.138,0,0,1,14,.136V1.227A.138.138,0,0,1,13.86,1.363Z" transform="translate(10 11)" fill="#fff"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default BurgerMenuIcon;