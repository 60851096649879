
import {SET_GAME_HISTORY} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function gameHistoryReducer(state = initialStore.gameHistory, action) {
    switch (action.type) {
        case SET_GAME_HISTORY:
            return {
                history : action.gameHistory.RoundModels.Items,
                BankerPercentage: action.gameHistory.BankerPercentage,
                PlayerPercentage: action.gameHistory.PlayerPercentage,
                TiePercentage: action.gameHistory.TiePercentage
            }
        default:
            return state;
    }
}