import React from "react";
import Modal from "@components/modal";
import {FormattedMessage} from "react-intl";
import {HistoryMobileIcon, InfoMobileIcon} from "@resource";


function RightMenu({handleCancel, submitMenu, show}) {

    return(
        <Modal handleCancel={handleCancel}
               show={show}
               parentClass="right-menu"
        >
            <div className="title"><FormattedMessage id="menu" /></div>
            <ul className="menu-list">
                <li className="menu-list-item" onClick={() => {submitMenu("history"); handleCancel()}}><HistoryMobileIcon /><FormattedMessage id="player_history"/></li>
                <li className="menu-list-item" onClick={() => {submitMenu("rules"); handleCancel()}}><InfoMobileIcon /><FormattedMessage id="game_rules"/></li>
            </ul>

        </Modal>
    )
}

export default RightMenu