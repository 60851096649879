import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {CardsSuit, CardTypeMap, Prediction, BetsType} from "@constants/Enums";
import {floatNumberWithSpaces} from "@services/numberFormatServices";

import RoundInfoItem from "./RoundInfoItem";
import CardList from "./CardList";

export function RoundHistory({round}) {
    const [bankerCards, setBankerCards] = useState([]);
    const [playerCards, setPlayerCards] = useState([]);

    useEffect(() => {
        let player = [], banker = [];
        if (round.bankerCards) {
            round.bankerCards.forEach(card => {
                banker.push( {type: CardTypeMap[card.type], suit: CardsSuit[card.suit]})
            })
        }

        if (round.playerCards) {
            round.playerCards.forEach(card => {
                player.push( {type: CardTypeMap[card.type], suit: CardsSuit[card.suit]})
            })
        }

        setBankerCards(banker);
        setPlayerCards(player)
    }, [round])
    
    return (
        <div className="history-round">
            <div className="history-round-info">
                <RoundInfoItem time={round.startTime}
                                timeTitle="game_start"
                                balance={round.balanceBefore}
                                balanceTitle="balance_before_the_round" />
                <RoundInfoItem time={round.endTime}
                                timeTitle="game_end"
                                balance={round.balanceAfter}
                                balanceTitle="balance_after_the_round" />
            </div>
            <div className="round-cards">
                <CardList points={round.bankerCardsWeight}
                          player={<FormattedMessage id="banker" />}
                          cards={{cards: bankerCards}}
                          winner={round.won === Prediction.PlayerB}/>

                <CardList points={round.playerCardsWeight}
                          player={<FormattedMessage id="player" />}
                          cards={{cards: playerCards}}
                          winner={round.won === Prediction.PlayerB}/>
            </div>
            <div className="round-bets">
                <div className="tbl">
                    <div className="row">
                        <div className="cell"><FormattedMessage id="bet_type" /></div>
                        <div className="cell"><FormattedMessage id="bet" /></div>
                        <div className="cell"><FormattedMessage id="odd" /></div>
                        <div className="cell"><FormattedMessage id="win_lose" /></div>
                    </div>
                    {
                        round.bets.map(item => (
                            <div className="row" key={item.prediction}>
                                <div className="cell"><FormattedMessage id={Prediction[item.prediction]} /></div>
                                <div className="cell">{floatNumberWithSpaces(item.stake)}</div>
                                <div className="cell">{floatNumberWithSpaces(item.odd)}</div>
                                <div className="cell">{floatNumberWithSpaces(item.status === BetsType.win ? item.win : item.status === BetsType.refund ? 0 : -item.stake || 0, 0)}</div>
                            </div>
                        ))
                    }
                    <div className="row">
                        <div className="cell"><FormattedMessage id="total" />:</div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => b.stake + a, 0))}</div>
                        <div className="cell"></div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => (b.status === BetsType.win ? b.win : b.status === BetsType.refund ? 0 : b.status === BetsType.lose ? -b.stake : 0) + a, 0), 0)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RoundHistory;