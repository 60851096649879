import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import { getFullscreenElement, fullScreenToggle, removeFullScreenEventListner, addFullScreenEventListner } from "@services/fullScreenService";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";
import {FullScreenOutIcon, FullScreenInIcon} from "@resource";

function FullScreen() {
    const [fullScreen, setFullScreen] = useState(!!getFullscreenElement())

    useEffect(() => {    
        const fullscreenchanged = (event) => {
            if (getFullscreenElement()) {
                setFullScreen(true);
            } else {
                setFullScreen(false);
            }
          }
          addFullScreenEventListner(fullscreenchanged);

          return () => {
            removeFullScreenEventListner(fullscreenchanged);
          }
    }, [])
    return(
        <li className={classNames(`template-button-item fullScreen desktop-icon`)} onClick={() =>fullScreenToggle()}>
            <ToolTip title={!!getFullscreenElement() ? <FormattedMessage id={"exit-full-screen"}/> :  <FormattedMessage id={"full-screen"}/>} position={"bottom"}>
                {!!getFullscreenElement() ? <FullScreenOutIcon /> : <FullScreenInIcon/>}
            </ToolTip>
        </li>
    )
}

export default FullScreen