import React from "react";

export default function musicOffIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="musicOnOffIcon" x="0" y="0" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="b"/>
                    <feFlood/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#musicOnOffIcon)">
                <path d="M20.362,21.746a3.478,3.478,0,0,1-1.5.34,3.428,3.428,0,0,1-3.424-3.424,3.382,3.382,0,0,1,.348-1.5L8.46,9.844V20.377a3.478,3.478,0,0,1-3.424,3.305,3.424,3.424,0,0,1,0-6.849l.468,0a.829.829,0,0,0,.7-.161.789.789,0,0,0,.326-.656v-8.1L0,1.384,1.384,0l24.81,24.809L24.81,26.194Zm-.72-6.483a1.052,1.052,0,0,0,.439-.162.994.994,0,0,0,.31-.681V6.4a1.067,1.067,0,0,0-.251-.66L20.1,5.7a.8.8,0,0,0-.681-.163l-8.556.951L7.439,3.06,22.323,1.4V17.945Z" transform="translate(11 10)" fill="#fff" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
        </svg>
    )
}