import React, {useState, useEffect} from "react";
import History from "@components/history/history";
import {FormattedMessage} from "react-intl";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";
import {HistoryIcon} from "@resource";

function PlayerHistory({mobileActions, setMobileActions}) {
    const [history, setHistory] = useState(false)

    useEffect(() => {
        if (mobileActions === "history" && !history) {
            setHistory(true)
        }
        if (!history) {
            setMobileActions("")
        }

    },[history,mobileActions])

    return (<>
            <li className={classNames(`template-button-item`, {active: history})} onClick={() => setHistory(prev => !prev)}>
                <ToolTip title={<FormattedMessage id={"player_history"}/>} position={"bottom"}>
                    {<HistoryIcon/>}
                </ToolTip>
            </li>
            <History handleCancel={setHistory} show={history}/>
    </>)
}

export default PlayerHistory