import {
    SUB_GAME_FINISH,
    RESET_USER_BET_LIST,
    SET_GAME_CONDITION,
    SET_ROUND_START,
    SUB_CANCEL_GAME,
    SET_TIME_TICKER,
    SET_ALERT_MSG,
    REQUEST_REFRESH_SESSION,
    REQUEST_GAME_START,
    CONNECT_TO_SOCKET,
    SUB_GAME_INIT,
    SET_GAME_INITIAL_DATA,
    SET_USER_INFO, SET_VIDEO_STREAM_CONFIG,
    SET_SERVER_TIME,
    SUB_ROUND_START,
    SET_WINNER_LIST,
    SET_BANKER_CARDS,
    SET_PLAYER_CARDS,
    RESET_ACTIVE_BET,
    SET_LIMIT,
    SUB_GAME_RESULT,
    SET_USER_WON,
    SET_ALL_BETS,
    SET_LOAD_INFO
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "@services/storageService";

export function subscribeRoundStartAction() {
    return dispatch => {
        dispatch({eventOn: SUB_ROUND_START,
            handle: (data) => {
                dispatch({type: SET_SERVER_TIME, time: data.Timer.Time});
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Timer.Start || 0, End: data.Timer.End || 0}});
                dispatch({type: SET_ROUND_START, roundId : data.RoundId});
                dispatch({type: SET_GAME_CONDITION, condition : true});
            }
        });
    }
}
export function subscribeGameFinish() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_FINISH,
            handle: (data) => {
                dispatch({type: SET_BANKER_CARDS, ...initialStore.cards})
                dispatch({type: SET_PLAYER_CARDS, ...initialStore.cards})
                dispatch({type: RESET_USER_BET_LIST});
                dispatch({type: SET_GAME_CONDITION, condition : false});
                dispatch({type: SET_ROUND_START, roundId : ""});
                removeLocalStorage("bets_" + data.RoundId)
                dispatch({type: RESET_ACTIVE_BET})
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
            }
        })
    }
}

export function subscribeGameResult() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_RESULT,
            handle: (data) => {
                dispatch({type: SET_USER_WON, wonAmount: {value: data.WonAmount}})
                dispatch({type: SET_WINNER_LIST, winners: data.Results});
            }
        })
    }
}

export function setUserWon(wonAmount) {
    return dispatch => {
        dispatch({type: SET_USER_WON, wonAmount})
    }
}

export function subscribeCanceledGame() {
    return dispatch => {
        dispatch({
            eventOn: SUB_CANCEL_GAME,
            handle: () => {
                dispatch({type: SET_BANKER_CARDS, ...initialStore.cards})
                dispatch({type: SET_PLAYER_CARDS, ...initialStore.cards})
                dispatch({type: SET_ROUND_START, roundId : ""});
                dispatch({type: SET_TIME_TICKER, ticker: {
                    Start: initialStore.timeTicker.start,
                    End: initialStore.timeTicker.end
                }});
                dispatch({type: SET_ALERT_MSG, data: {type: 'info', messages: "game_is_canceled_your_bets_will_refund"}})
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
            }
        })
    }
}

export function refreshSession(session) {
    return dispatch => {
        dispatch({
            type: REQUEST_REFRESH_SESSION,
            session
        })
    }
}

export function gameStart() {
    return dispatch => {
        dispatch({
            type: REQUEST_GAME_START,
        })
    }
}

export function connectToSocket(session) {
    return dispatch => {
        dispatch({
            type: CONNECT_TO_SOCKET,
            session
        })
    }
}

export function subGameInitData() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_INIT,
            handle: (data) => {
                let unAcceptedBets = [], bets = []
                var userBookData = [];
                dispatch({
                    type: SET_BANKER_CARDS,
                    cards: data.Cards ? data.Cards.BankerCards : initialStore.emptyArr,
                    cardsWeight: data.Cards ? data.Cards.BankerCardsWeight : 0
                })
                dispatch({
                    type: SET_PLAYER_CARDS,
                    cards: data.Cards ? data.Cards.PlayerCards : initialStore.emptyArr,
                    cardsWeight: data.Cards ? data.Cards.PlayerCardsWeight : 0
                })
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Timer?.Start || 0, End: data.Timer?.End || 0}});
                dispatch({type: SET_GAME_INITIAL_DATA, ...data});
                dispatch({type: SET_USER_INFO, user: data.User});
                dispatch({type: SET_VIDEO_STREAM_CONFIG, config: data.Video});
                dispatch({type: SET_ROUND_START, roundId : data.RoundStart?.RoundId || ""});
                if(data.Timer?.time) {
                    dispatch({type: SET_SERVER_TIME, time: data.Timer.time});
                }
                dispatch({type: SET_LIMIT, limit: data.Limit})
                if (data.User.Id && data.RoundStart?.RoundId) {
                    unAcceptedBets = getLocalStorage(`unAcceptedBets_${data.User.Id}_${ data.RoundStart.RoundId}`) || []
                }
                if (data.PlayerBets?.Bets.length) {
                    data.PlayerBets.Bets.forEach(bet => {
                        bets.push({
                            accepted: true,
                            Prediction: bet.Prediction,
                            Stake: bet.Stake,
                            Odd: bet.Odds
                        })
                    })
                    bets.forEach(item => {
                        userBookData['prediction-'+item.Prediction] = item.Stake;
                    })
                    dispatch({type: SET_LOAD_INFO, load: userBookData || initialStore.loadInfo});
                }
                if (data.RoundStart?.RoundId && data.User?.Id) {
                    setLocalStorage(`acceptedBets_${data.User.Id}_${data.RoundStart.RoundId}`, bets)
                }
                dispatch({type: SET_ALL_BETS, bets: [...unAcceptedBets, ...bets], startTime:  data.Timer?.Start});
            
            }
        })
    }
}