import {
    SET_ROUND_START,
    SET_GAME_INITIAL_DATA
} from "@actions/actionTypes";

export default function roundIdReducer(state = "", action) {
    switch (action.type) {
        case SET_ROUND_START:
            return action.roundId
        case SET_GAME_INITIAL_DATA:
            return action.RoundStart?.RoundId || ""
        default:
            return state;
    }
}