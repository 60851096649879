import React from "react";

function PlusIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 26.1">
            <defs>
                <filter id="PlusIconUnion_67" x="0.4" y="1.9" width="23.181" height="24.2" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_2918" data-name="Group 2918" transform="translate(-1696 -848)">
                <rect id="Rectangle_12437" data-name="Rectangle 12437" width="24" height="24" transform="translate(1696 848)" fill="none" opacity="0.5"/>
                <g transform="matrix(1, 0, 0, 1, 1696, 848)" filter="url(#PlusIconUnion_67)">
                    <path id="Union_67-2" className="plus-icon-bg" data-name="Union 67" d="M5.01,12c-.083,0-.128-.044-.128-.135V6.634H.125C.041,6.634,0,6.59,0,6.5v-1c0-.091.041-.135.125-.135H4.882V.135A.119.119,0,0,1,5.01,0h.96C6.053,0,6.1.048,6.1.135V5.366H10.85c.088,0,.131.044.131.135v1c0,.091-.042.135-.131.135H6.1v5.231c0,.091-.044.135-.127.135Z" transform="translate(6.5 6)" fill="#fff" stroke="#fff" strokeWidth="0.2"/>
                </g>
            </g>
        </svg>
    )
}

export default PlusIcon