import React from "react";

function MinusIcon() {
   return (
       <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
           <defs>
               <filter id="MinusIconVector" x="0.4" y="7.4" width="23.181" height="13.466" filterUnits="userSpaceOnUse">
                   <feOffset dy="2" input="SourceAlpha"/>
                   <feGaussianBlur stdDeviation="2" result="blur"/>
                   <feFlood/>
                   <feComposite operator="in" in2="blur"/>
                   <feComposite in="SourceGraphic"/>
               </filter>
           </defs>
           <g id="Group_2917" data-name="Group 2917" transform="translate(-1696 -848)">
               <rect id="Rectangle_12437" data-name="Rectangle 12437" width="24" height="24" transform="translate(1696 848)" fill="none" opacity="0.5"/>
               <g transform="matrix(1, 0, 0, 1, 1696, 848)" filter="url(#MinusIconVector)">
                   <path id="Vector-2" className="minus-icon-bg" data-name="Vector" d="M.128,0H10.853q.128,0,.128.133v1q0,.133-.128.133H.128Q0,1.266,0,1.133v-1Q0,0,.128,0Z" transform="translate(6.5 11.5)" fill="#fff" stroke="#fff" strokeWidth="0.2"/>
               </g>
           </g>
       </svg>
   )
}

export default MinusIcon