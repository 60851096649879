import {
    SEND_GAME_HISTORY,
    SUB_GAME_HISTORY,
    SET_GAME_HISTORY
} from "@actions/actionTypes";

export function subscribeGameHistory() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_HISTORY,
            handle: (data) => {
                dispatch({type: SET_GAME_HISTORY, gameHistory: data})
            }
        })
    }
}

export function getGameHistory(limit, skip) {
    return dispatch => {
        const body = {
            Limit: limit,
            Skip: skip,
        }
        dispatch({
            eventEmit: SEND_GAME_HISTORY,
            emitData: body,
            type: SEND_GAME_HISTORY
        })
    }
}