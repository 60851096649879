import {
    SET_GAME_CONDITION,
    SEND_BET,
    SET_INACTIVITY
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function inactivityReducer(state = initialStore.inactivity, action) {
    switch (action.type) {
        case SET_GAME_CONDITION:
            return {...state, count: !action.condition ? state.count + 1 : state.count}
        case SEND_BET:
            return {...state, count: -1}
        case SET_INACTIVITY:
            if (action.payload) {
                return {...state, suspend : true}
            } else {
                return initialStore.inactivity
            }
        default:
            return state;
    }
}